import { CourseType, DefaultType, HomeTypes, ClientsType, ServicesType } from "./Types";
import { CoursesPageType } from "./pt/pages/PagesTypes";

export const defaultData = (lang: string): DefaultType => {
  const data: DefaultType = require(`./${lang}/default.json`);
  return data;
};

export const homeData = (lang: string, file: string): HomeTypes | undefined => {
  let data: HomeTypes = require(`./${lang}/pages/${file}.json`);

  if (data) return data;
  else return undefined;
};
export const clientsData = (lang: string, file: string): ClientsType | undefined => {
  let data: ClientsType = require(`./${lang}/pages/${file}.json`);

  if (data) return data;
  else return undefined;
};
export const servicesData = (lang: string, file: string): ServicesType | undefined => {
  let data: ServicesType = require(`./${lang}/pages/${file}.json`);

  if (data) return data;
  else return undefined;
};

export const coursesData = (lang: string, file: string): CoursesPageType | undefined => {
  let data: CoursesPageType = require(`./${lang}/pages/${file}.json`);

  if (data) return data;
  else return undefined;
};

export const coursePageData = (
  lang: string,
  file: string
): CourseType | undefined => {
  try {
    const data: CourseType | undefined = require(`./${lang}/pages/${file}.json`);
  
    if (data) return data;
    else return undefined;
  }catch {
    return undefined;
  }
};
