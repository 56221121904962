import React, { FC } from "react";

import "./Advantages.scss";
import { AdvantagesType } from "./Types";

const Advantages: FC<AdvantagesType> = ({ data }) => {
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <section id="advantages" className="advantages__content">
      <ul className="advantages__list">
        {data &&
          data.map((info) => (
            <li key={info.text} className="advantages__item">
              <img
                className="advantages__item--img"
                src={`${publicUrl}/${info.ico}`}
                alt={info.text}
              />
              {info.text && (
                <p
                  className="advantages__item--title"
                  dangerouslySetInnerHTML={{ __html: `${info.text}` }}
                ></p>
              )}
            </li>
          ))}
      </ul>
    </section>
  );
};

export default Advantages;
