import React, { FC } from "react";
import Slider from "react-slick";

import Card from "../../../UI/organisms/Card/Card";
import Title from "../../../UI/organisms/Title/Title";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Reviews.scss";

import { ReviewsType } from "./Types";
import ArrowLeft from "../../../UI/atoms/arrowLeft";
import ArrowRight from "../../../UI/atoms/arrowRight";

const responsive = (op: string | undefined) => [
  {
    breakpoint: 8000,
    settings: {
      slidesToShow: op === "image" ? 4 : 3,
      slidesToScroll: 1,
      infinite: false,
      dots: true,
    },
  },
  {
    breakpoint: 1300,
    settings: {
      slidesToShow: op === "image" ? 3 : 2,
      slidesToScroll: 1,
      infinite: false,
      dots: true,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: op === "image" ? 2 : 1,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 472,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

const Reviews: FC<ReviewsType> = ({ data, position }) => {
  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    prevArrow: (
      <div className="arrow-left">
        <ArrowLeft />
      </div>
    ),
    nextArrow: (
      <div className="arrow-left">
        <ArrowRight />
      </div>
    ),
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: responsive(data.type && data.type),
  };

  return (
    <section id="reviews" className={`reviews-${position}`}>
      <div className="reviews__content">
        <Title info={data.title} tag="h2" />
        <Slider className="reviews__list" {...settings}>
          {data.cards &&
            data.cards.map((card, index) => (
              <Card
                key={`${index}-${card.title}`}
                info={card}
                classItem={"reviews__list--item"}
              />
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Reviews;
